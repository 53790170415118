import React, { useState } from "react"
import "./styledheader.css"

import * as S from './styled'

import ResponsivePlayerHeader from "../../components/ResponsivePlayerHeader"

const HeaderVideo = () => {
  const [watchComplete, setWatchComplete] = useState(false)

  const handleWatchComplete = ({ playedhead }) => {
    if (playedhead >= 0.7 && !watchComplete) {
      setWatchComplete(true)
    }
  }

  return (
    <div className="Container">
      <div className="Info">
      <h2>MESTRADO E DOUTORADO EAD</h2>
      <S.Title2>ABSOULUTE CHRISTIAN UNIVERSITY</S.Title2>
      <p>UNIVERSIDADE CRISTÃ, TOTALMENTE ONLINE, RECONHECIDA PELA COMISSÃO INDEPENDENTE DA SECRETARIA DE EDUCAÇÃO DO ESTADO DA FLÓRIDA/EUA.<br/><br/>
      </p>
      <h2>COM TÍTULOS RECONHECIDOS POR UNIVERSIDADES BRASILEIRAS.</h2>
      </div>
    <S.Box>
      <ResponsivePlayerHeader
        url="https://youtu.be/qLeRTnDOrWU"
        onProgress={handleWatchComplete}
      />
    </S.Box>
    </div>
  )
}

export default HeaderVideo