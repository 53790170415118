import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 120px;
    background-color: #6c51a6;
`

export const Title = styled.h1`
    font-size: 42px;
    font-weight: 700;
    color: rgba(255,255,255,.8);
    text-align: center;
`