import React from 'react'

import * as S from './styled'

import "./main.css"

const Faq = () => (
    <section>
        <S.Container>
        <S.TitleWrapper>
            <S.Title>Perguntas e Respostas</S.Title>
        </S.TitleWrapper>
            <S.Acordeon>
            <div className="accordion-item" id="pergunta1">
                    <S.AcordeonLink href="#pergunta1">
                    1. Qual o significado de reconhecimento de diplomas estrangeiros?
                        <S.IconAdd />
                    </S.AcordeonLink>
                    <div className="answer">
                        <S.Description><br/>
                        R.: O reconhecimento de diplomas estrangeiros no Brasil é 
                        um procedimento que habilita o portador de um diploma estrangeiro a 
                        utilizá-lo no Brasil como se fosse um diploma brasileiro.<br/><br/>

                        Esse procedimento é necessário somente em algumas situações excepcionais nas 
                        quais há exigência governamental de comprovação de título equivalente ao diploma 
                        brasileiro de mestrado ou doutorado.<br/><br/>

                        O reconhecimento declara que um diploma estrangeiro, que já é um diploma 
                        válido no país de origem, é também equivalente a um diploma brasileiro 
                        perante o governo.<br/><br/>

                        O reconhecimento de mestrado e doutorado é corriqueiro no Brasil e 
                        historicamente muitos professores que realizam seus estudos no exterior, 
                        assim que voltam para cá, buscam reconhecer seu diploma de mestre ou doutor.<br/><br/>

                        Ressaltamos também que o termo revalidação é aplicado somente a diplomas de graduação.<br/><br/>

                        O termo correto direcionado para diplomas de mestrado e doutorado é reconhecimento de 
                        diplomas conforme consta na Resolução 03 de 2016.<br/><br/>
                        </S.Description>
                    </div>
                </div>
                <div className="accordion-item" id="pergunta2">
                    <S.AcordeonLink href="#pergunta2">
                    2. Qual é a diferença entre revalidação e reconhecimento de diplomas?
                        <S.IconAdd />
                    </S.AcordeonLink>
                    <div className="answer">
                        <S.Description>
                        R.: Conforme Resolução Nº 03, de 22 de junho de 2016, a Revalidação de Diplomas é 
                        aplicado para tramitação nos processos de Graduação realizado em Instituições 
                        estrangeiras, enquanto o reconhecimento de diplomas estrangeiros se aplicará aos 
                        diplomas de cursos de pós-graduação stricto sensu (mestrado e/ou doutorado), 
                        expedidos por universidades estrangeiras.
                        </S.Description>
                    </div>
                </div>
                <div className="accordion-item" id="pergunta3">
                    <S.AcordeonLink href="#pergunta3">
                    3. Em qual Instituição brasileira pode tramitar esse processo?
                        <S.IconAdd />
                    </S.AcordeonLink>
                    <div className="answer">
                        <S.Description>
                        R.: Para o grau de mestrado e doutorado, os processos de reconhecimento 
                        de títulos de Mestre ou Doutor podem tramitar tanto pelas universidades 
                        públicas quanto pelas privadas, que possuam o curso na área que se busca o 
                        reconhecimento avaliado e recomendado pela CAPES.
                        </S.Description>
                    </div>
                </div>
                <div className="accordion-item" id="pergunta4">
                    <S.AcordeonLink href="#pergunta4">
                    4. Então é possível fazer o Reconhecimento em Universidades Privadas?
                        <S.IconAdd />
                    </S.AcordeonLink>
                    <div className="answer">
                        <S.Description>
                        R.: Sim. As instituições privadas possuem poderes para reconhecimento de títulos de mestrados ou 
                        doutorados do exterior.<br/><br/>

                        As universidades privadas podem declarar que diplomas de mestrados e doutorados do exterior são 
                        equivalentes aos pares brasileiros.<br/><br/>
                        </S.Description>
                    </div>
                </div>
                <div className="accordion-item" id="pergunta5">
                    <S.AcordeonLink href="#pergunta5">
                    5. Então, no fim das contas, qualquer instituição de ensino do Brasil que tenha mestrado ou doutorado 
                    tem autonomia e poder para declarar cursos do exterior como equivalentes aos brasileiros que ela ofereça?
                        <S.IconAdd />
                    </S.AcordeonLink>
                    <div className="answer">
                        <S.Description>
                        R.: Não. Somente Universidade Publica ou Privada, que tenha o curso avaliado e recomendado pela 
                        CAPES, com nota superior a 4, na mesma área de Concentração que se busca o reconhecimento, pode 
                        reconhecer um diploma de mestrado e/ou doutorado estrangeiro no Brasil.
                        </S.Description>
                    </div>
                </div>
                <div className="accordion-item" id="pergunta6">
                    <S.AcordeonLink href="#pergunta6">
                    6. Os critérios do processo são estabelecidos por cada instituição e possuem pontos em comum como?
                        <S.IconAdd />
                    </S.AcordeonLink>
                    <div className="answer">
                        <S.Description>
                        R.: Sim. Cada Universidade tem seus critérios de avaliação e exigências de documentação, 
                        comissão de verificação de equivalência e decisão final.<br/><br/>

                        São as próprias instituições, sejam públicas ou privadas, que estabelecem quais documentos 
                        devem ser entregues para criação de um processo administrativo interno de pedido de reconhecimento 
                        de diploma de mestrado ou doutorado.
                        </S.Description>
                    </div>
                </div>
                <div className="accordion-item" id="pergunta7">
                    <S.AcordeonLink href="#pergunta7">
                    7. Quais são os documentos solicitados?
                        <S.IconAdd />
                    </S.AcordeonLink>
                    <div className="answer">
                        <S.Description>
                        R.: Dependerá do regimento interno de cada Instituição, mas o processo sempre se inicia por meio de 
                        requerimento dirigido ao Reitor.<br/><br/>

                        Normalmente os documentos exigidos, não maioria das vezes, são:<br/><br/>

                        Documentos do Curso:<br/>
                        . Diploma estrangeiro a ser reconhecido;<br/>
                        . Histórico acadêmico do curso;<br/>
                        . Ementário com conteúdo programático estudado;<br/><br/>

                        Documentos pessoais:<br/>
                        . Identidade<br/>
                        . CPF<br/>
                        . Comprovante de residência<br/>
                        . Título de Eleitor <br/>
                        . Comprovante de Quitação Eleitoral<br/>
                        . Demais documentos especificados pela instituição de ensino do Brasil.<br/><br/>

                        Normalmente, as universidades solicitam cópias da dissertação de mestrado ou tese de doutorado.<br/><br/>

                        Historicamente, a qualidade da dissertação para mestrado e da tese para doutorado possuem o 
                        maior peso em solicitações de reconhecimento do título de mestre ou doutor.<br/><br/>

                        Ao final do processo, o Reitor ou um colegiado da universidade confirma ou não o parecer da 
                        comissão que pode ser pelo reconhecimento ou não do diploma.<br/><br/>
                        </S.Description>
                    </div>
                </div>
                <div className="accordion-item" id="pergunta8">
                    <S.AcordeonLink href="#pergunta8">
                    8. É exigência a Tradução juramentada?
                        <S.IconAdd />
                    </S.AcordeonLink>
                    <div className="answer">
                        <S.Description>
                        R.: Segundo o Resolução 03/16, a tradução juramentada é desnecessária quando os documentos 
                        estão escritos em inglês, francês ou espanhol.
                        </S.Description>
                    </div>
                </div>
                <div className="accordion-item" id="pergunta9">
                    <S.AcordeonLink href="#pergunta9">
                    9. Qual o tempo para ocorrer o reconhecimento do diploma?
                        <S.IconAdd />
                    </S.AcordeonLink>
                    <div className="answer">
                        <S.Description>
                        R.: Na Resolução 03/16, após a homologação dos documentos o tempo em média é 
                        de 6 meses, mas na prática temos observado os seguintes prazos:<br/>
                        a. Até a homologação dos documentos - de 45 a 60 dias;<br/>
                        b. Após homologação até o resultado final - de 8 a 12 meses.
                        </S.Description>
                    </div>
                </div>
                <div className="accordion-item" id="pergunta10">
                    <S.AcordeonLink href="#pergunta10">
                    10. Como conseguir a documentação completa?
                        <S.IconAdd />
                    </S.AcordeonLink>
                    <div className="answer">
                        <S.Description>
                        R.: A documentação deve ser providenciadas pelo interessado e é de suma importância 
                        apresentar o diploma devidamente chancelado pela embaixada ou consulado brasileiro 
                        localizado no país de origem da Universidade, caso o país seja membro da convenção de Haia, 
                        o mesmo deve estar apostilado.
                        </S.Description>
                    </div>
                </div>
                <div className="accordion-item" id="pergunta11">
                    <S.AcordeonLink href="#pergunta11">
                    10. Como conseguir a documentação completa?
                        <S.IconAdd />
                    </S.AcordeonLink>
                    <div className="answer">
                        <S.Description>
                        R.: Ao longo dos últimos anos, vimos vários brasileiros que acreditavam que sem o 
                        reconhecimento no Brasil o seu diploma estrangeiro não teria valor. Na prática, 
                        não é bem assim que o mundo funciona.<br/><br/>

                        O primeiro ponto a ser entendido é que o Brasil não é o único país que possui 
                        autoridade para conceder título de acadêmicos de graduação, mestrado e doutorado.<br/><br/>

                        Em verdade, o Brasil emite muitos poucos títulos acadêmicos quando comparados a outros países, 
                        pois o acesso ao ensino superior no Brasil ainda é insipiente, limitado e não se faz uso 
                        disseminado de técnicas de ensino a distância para a democratização do ensino.<br/><br/>

                        Isto é, ao concluir um curso seja de mestrado ou doutorado em qualquer país do mundo, 
                        você terá um título de mestre ou doutor de acordo com a legislação educacional do 
                        país onde sua instituição de ensino estiver sediada.<br/><br/>

                        Após a diplomação no país de origem, você já é mestre ou doutor.

                        O reconhecimento no Brasil somente faz sentido e é necessário quando você precisa comprovar junto ao governo 
                        brasileiro, seja na esfera municipal, Estadual ou federal.<br/><br/>
                        </S.Description>
                    </div>
                </div>
                <div className="accordion-item" id="pergunta12">
                    <S.AcordeonLink href="#pergunta12">
                    12. Qual a Legislação de amparo ao reconhecimento de títulos de mestre e doutor do exterior no Brasil?
                        <S.IconAdd />
                    </S.AcordeonLink>
                    <div className="answer">
                        <S.Description>
                        R.: A  Plataforma Carolina Bori apresenta todos os detalhes da legislação sobre o tema e também 
                        centraliza pedidos de reconhecimento de títulos para muitas instituições de ensino públicas e privadas.<br/><br/>

                        Você pode pesquisar a Resolução 03, de 22 de junho de 2016, artigo 18, parágrafo 4 a partir do 
                        Inciso I, acesse o link abaixo:<br/><br/>
                        
                        <S.LinkWapper>
                        <a href="http://portal.mec.gov.br/index.php?option=com_docman&view=download&alias=44661-rces003-16-pdf&category_slug=junho-2016-pdf&Itemid=30192" target="_blank" rel="noreferrer">
                        RESOLUÇÃO Nº 3, DE 22 DE JUNHO DE 2016 (*)</a><br/><br/>
                        </S.LinkWapper>

                        Você também poderá pesquisar a Portaria Normativa 22, de 13 de dezembro de 2016, acesse o link abaixo:<br/><br/>

                        <S.LinkWapper>
                        <a href="https://www.capes.gov.br/images/stories/download/legislacao/14122016-PORTARIA-NORMATIVA-N-22-DE-13-DE-DEZEMBRO-DE-2016.pdf" target="_blank" rel="noreferrer">
                        Diário Oficial da União - Seção 1</a><br/><br/>
                        </S.LinkWapper>

                        Estamos a disposição para maiores informações sobre o processo de reconhecimento.

                        </S.Description>
                    </div>
                </div>
            </S.Acordeon>
        </S.Container>
    </section>
)

export default Faq