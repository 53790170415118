import styled from 'styled-components'
import media from "styled-media-query";

export const Cardwrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  ${media.lessThan("medium")`
  
  `}
  
`