import styled from 'styled-components'

export const Box = styled.div`
    width: 100%;
    margin-bottom: 32px;
`
export const Title2 = styled.h1`
  font-size: 52px;
  font-weight: 700;
  color: #282828;
  margin: 10px;
  text-align: center;
`