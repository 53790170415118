import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import * as S from './styled'

const BannerAcu
 = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "banner-acu.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3435) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <S.Image fluid={data.placeholderImage.childImageSharp.fluid} />
}

export default BannerAcu

