import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import * as S from './styled'


import TopInfo from '../../components/TopInfo'
import HeaderVideo from '../../components/HeaderVideo'
import SectionsInfo from '../../components/SectionsInfo'
import Matricula from '../../components/Matricula'
import Invista from '../../components/Invista'
import ProfVideo from '../../components/ProfVideo'
import AlunosEstudandoVideo from '../../components/AlunosEstudandoVideo'
import AlunosBancaVideo from '../../components/AlunosBancaVideo'
import BannerAcu from '../../components/BannerAcu'
import Footer from '../../components/Footer'
import Faq from '../../components/Faq'
import Price from '../../components/Price'




import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ bghome }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "fundo.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={bghome}
          fluid={imageData}
          backgroundColor={`#fff`}
        >
          <S.Cardwrapper>
            <TopInfo />
            <HeaderVideo />
            <SectionsInfo />
            <Matricula />
            <Invista />
            <ProfVideo />
            <AlunosEstudandoVideo />
            <AlunosBancaVideo />
            <Faq />
            <BannerAcu />
            <Matricula />
            <Price />
            <Footer />
          </S.Cardwrapper>
        </BackgroundImage>
      )
    }}
  />
)

export default BackgroundSection