import React from 'react'

import LogoAbsoulute from '../../components/LogoAbsoulute'
import BtnAction from '../../components/BtnAction'
import * as S from './styled'

const TopInfo = () => (
    <S.Container>
        <S.Box>
        <LogoAbsoulute />
        <BtnAction />
        </S.Box>
    </S.Container>
)

export default TopInfo