import React, { useState } from "react"

import "../../styles/videos.css"

import ResponsivePlayer from "../ResponsivePlayer"

const AlunosBancaVideo = () => {
  const [watchComplete, setWatchComplete] = useState(false)

  const handleWatchComplete = ({ played }) => {
    if (played >= 0.7 && !watchComplete) {
      setWatchComplete(true)
    }
  }

  return (
    <div className="Container">
      <div className="Info">
      <h1>Depoimentos de Alunos Aprovados em Banca de Avaliação</h1>
      </div>
      <div className="BoxWrapper">
    <div className="Box">
      <ResponsivePlayer
        url="https://vimeo.com/432504938"
        onProgress={handleWatchComplete}
      />
    </div>
    <div className="Box">
      <ResponsivePlayer
        url="https://vimeo.com/432504878"
        onProgress={handleWatchComplete}
      />
    </div>
    <div className="Box">
      <ResponsivePlayer
        url="https://vimeo.com/432504791"
        onProgress={handleWatchComplete}
      />
    </div>
    </div>
    </div>
  )
}

export default AlunosBancaVideo