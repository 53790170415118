import styled from 'styled-components'
import media from 'styled-media-query'


export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px auto 32px auto;
    background: #fff;
    width: 366px;
    height: 448px;
    -webkit-box-shadow: 7px 9px 12px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 7px 9px 12px 0px rgba(0,0,0,0.75);
    box-shadow: 7px 9px 12px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
    z-index: 999;

  ${media.lessThan("small")`
    width: 315px;
  `}
`

export const Title = styled.h1`
    color: var(--blue-color);
    font-size: 22px;
    font-weight: 700;
    margin: 25px auto;
`

export const description = styled.p`
    color: var(--blue-color);
    text-align: center;
    font-size: 18px;
    margin: 10px 10px 20px 10px;
`