import styled from 'styled-components'

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    margin: 0;
`

export const InputBox = styled.div`
    position: relative;
    margin-top: -10px;
`

export const InputBoxInput = styled.input`
    width: 290px;
    padding: 15px 0;
    font-size: 16px;
    margin-bottom: 15px;
    color: #4c4c4c;
    border: none;
    border-bottom: 1px solid #666666;
    outline: none;
    background: transparent;
    `

export const Label = styled.label`
    position: absolute;
    margin-top: 5px;
    top: 0;
    left: 0;
    padding: 10px 0;
    font-size: 18px;
    color: var(--blue-color);
    pointer-events: none;
    transition: .5s;

     ${InputBoxInput}:focus ~ &,
     ${InputBoxInput}:valid ~ & {
        top: -18px;
        left: 0%;
        color: var(--orange-color);
        font-size: 16px;
  }
`
export const InputSubmit = styled.input`
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    margin: 1rem 0;
    font-size: 18px;
    outline: none;
    border: none;
    background-image: linear-gradient(to right, var(--orange-color), #BF7F2C, var(--orange-color));
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
    background-size: 200%;
    transition: .5s;

    &:hover {
        background-position: right
    }
`
