import styled from 'styled-components'
import { Connectdevelop } from '@styled-icons/fa-brands/Connectdevelop'
import { Link } from 'gatsby'
import media from 'styled-media-query'

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 100px 100px 50px 100px; 
    background-color: var(--blue-color);

    ${media.lessThan("large")`
    display: flex;
    flex-direction: column;
  `}

    ${media.lessThan("medium")`
    display: flex;
    flex-direction: column;
    padding: 32px 20px 50px 20px; 
  `}
`
export const Title = styled.h1`
    font-size: 52px;
    font-weight: 700;
    color: var(--orange-color);
    margin: 32px;

    ${media.lessThan("medium")`
    font-size: 42px;
  `}

  
`

export const PriceWrapper = styled.div`
    margin-left: 32px;
`

export const TitlePriceWrapper = styled.div`

`
export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 10px;
`

export const Icon = styled(Connectdevelop)`
    width: 42px;
    margin: 10px;
    color: var(--orange-color);
`
export const TitlePrice = styled.h1`
    color: rgba(255,255,255,.8);
    font-size: 22px;
`

export const TitleItem = styled.div`
    color: rgba(255,255,255,.8);
    font-size: 22px;
    margin: 10px;
`
export const PriceBefore = styled.span`
    text-decoration: line-through;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 700;
`
export const PriceAfter = styled.span`
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 700;
    color: var(--orange-color);
    font-size: 28px;

    ${media.lessThan("medium")`
    font-size: 22px;
  `}
`

export const Discount = styled.div`
    color: rgba(255,255,255,.8);
    font-size: 22px;
    margin: 10px;

    ${media.lessThan("medium")`
        line-height: 32px;
  `}
`
export const Percentage = styled.span`
    padding: 5px;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 700;
    background-color: var(--orange-color);
`

export const ButtomWrapper = styled.div`

`

export const InputSubmit = styled(Link)`
    display: block;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    margin: 1rem 0;
    font-size: 18px;
    outline: none;
    border: none;
    background-image: linear-gradient(to right, var(--orange-color), #BF7F2C, var(--orange-color));
    cursor: pointer;
    color: rgba(255,255,255,.8);
    text-transform: uppercase;
    background-size: 200%;
    transition: .5s;

    &:hover {
        background-position: right
    }
`

