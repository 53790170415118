import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 12vh;

    -webkit-box-shadow: 0px 4px 5px 0px rgba(13, 41, 69, .7);
    -moz-box-shadow: 0px 4px 5px 0px rgba(13, 41, 69, .7);
    box-shadow: 0px 4px 5px 0px rgba(13, 41, 69, .7);
`

export const Box = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 5%;
`

export const BtnWrapper = styled.div`

`