import React from "react"

import { InputSubmit } from './styled'

const SubscribeSucess = () => {
  
  return (
    <InputSubmit href="https://api.whatsapp.com/send?phone=5582999762839" target="__blank"
    value="Quero Saber Mais">
    <span>Quero Saber Mais</span>
    </InputSubmit>
  )
}


export default SubscribeSucess
