import React from 'react'

import './styled.css'

const BtnAction = () => (
    <div>
    <div className="btn btn-one">
    <a href="tel:(82)99976-2839" target="_blank" rel="noopener noreferrer">
    (82) 99976-2839</a>
    </div>
    </div>
)

export default BtnAction