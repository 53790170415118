import styled from 'styled-components'
import media from 'styled-media-query'
import { Check } from '@styled-icons/boxicons-regular/Check'


export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: #282828;
  width: 100%;

  ${media.lessThan("huge")`
  
  `}

  ${media.lessThan("large")`
  
  `}

  ${media.lessThan("medium")`
  
  `}

  ${media.lessThan("small")`
  
  `}
  
`

export const TitleOneWrapper = styled.div`
  border-left: solid 3px rgba(255,255,255,.8);
`

export const TitleOne = styled.h2`
  font-size: 18px;
  color: var(--orange-color);
  margin-left: 10px;
`

export const TitleOtwe = styled.h1`
color: rgba(255,255,255,.8);
font-size: 32px;
font-weight: 700;
margin-top: 11px;
`


export const DescriptionWrapper = styled.div`
  display: flex;
`

export const Icon1 = styled(Check)`
  width: 32px;
  color: var(--orange-color);
`

export const Description = styled.p`
  font-size: 18px;
  color: rgba(255,255,255,.8);
  margin-top: 11px;

  span {
    font-size: 14px;
  }
`

export const Box1 = styled.div`
  width: 400px;
  margin: 10px 20px;
`

export const Box2 = styled.div`
  margin: 10px 20px;
`