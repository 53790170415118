import React from 'react'

import Image1  from '../ImagesInfo/Image1'
import Image2  from '../ImagesInfo/Image2'
import Image3  from '../ImagesInfo/Image3'
import Image4  from '../ImagesInfo/Image4'
import Image5  from '../ImagesInfo/Image5'
import Image6  from '../ImagesInfo/Image6'

import * as S from './styled'

const SectionsInfo = () => (
    <S.Container>

        {/* Parte 1 */}
        <S.Box1>
            <S.TitleOneWrapper>
            <S.TitleOne>AMBIENTE VIRTUAL</S.TitleOne>
            </S.TitleOneWrapper>
            <S.TitleOtwe>Nossos alunos possuem acesso a um ambiente virtual exclusivo e dinâmico.</S.TitleOtwe>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Ambiente virtual de aprendizagem;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Ambiente Virtual onde ocorre as aulas online, ao vivo, com interação com os professores;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Ambiente Virtual do Orientador;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Ambiente Virtual para Produção Acadêmica e construção da dissertação, tese e artigos científicos.</S.Description>
            </S.DescriptionWrapper>
        </S.Box1>
        <S.Box2>
            <Image1 />
        </S.Box2>
        {/* Final parte 1 */}


        {/* Parte 2 */}
        <S.Box2>
            <Image2 />
        </S.Box2>
        <S.Box1>
            <S.TitleOneWrapper>
            <S.TitleOne>PORTAL DO ALUNO</S.TitleOne>
            </S.TitleOneWrapper>
            <S.TitleOtwe>Portal do Aluno online e de fácil acesso.</S.TitleOtwe>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Ambiente virtual de aprendizagem;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Portal acessível e responsivo para todas plataformas<br/><span>[COMPUTADOR, IPHONE E ANDROID];</span></S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Vídeoaula;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Quiz;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Saiba Mais sobre a Disciplina;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Desafio Cientíco;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Fórum de Discussão;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Avalie sua Aula.</S.Description>
            </S.DescriptionWrapper>
        </S.Box1>
        {/* Final Parte 2 */}

        {/* Parte 3 */}
        <S.Box1>
            <S.TitleOneWrapper>
            <S.TitleOne>BENEFÍCIOS</S.TitleOne>
            </S.TitleOneWrapper>
            <S.TitleOtwe>Benefícios Exclusivos para nossos alunos.</S.TitleOtwe>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Orientador(a) disponível para auxiliar os alunos na construção dos artigos, dissertação ou tese;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Professores(as) Doutores(as) com experiência para as aulas online ao vivo;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Banca e Avaliação online.</S.Description>
            </S.DescriptionWrapper>
        </S.Box1>
        <S.Box2>
            <Image3 />
        </S.Box2>
        {/* Final parte 3 */}

        {/* Parte 4 */}
        <S.Box2>
            <Image4 />
        </S.Box2>
        <S.Box1>
            <S.TitleOneWrapper>
            <S.TitleOne>+ BENEFÍCIOS</S.TitleOne>
            </S.TitleOneWrapper>
            <S.TitleOtwe>Outros Benefícios e Reconhecimentos.</S.TitleOtwe>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Programas de Cursos em equivalência com a maioria das Universidades Brasileiras;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Áreas de concentração focadas em suprir as demandas de mercado de trabalho;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Linhas de Pesquisa Científica em consonância com as demandas acadêmicas;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Colegiado formado por Doutores(as) qualificados(as) dentro da área de formação dos alunos;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Diplomação dos alunos em tempo recorde;<br/><span>90 dias.</span></S.Description>
            </S.DescriptionWrapper>
        </S.Box1>
        {/* Final Parte 4 */}

        {/* Parte 5 */}
        <S.Box1>
            <S.TitleOneWrapper>
            <S.TitleOne>AINDA + BENEFÍCIOS</S.TitleOne>
            </S.TitleOneWrapper>
            <S.TitleOtwe>Outros Benefícios e Reconhecimentos.</S.TitleOtwe>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />O Diploma da Absoulute Christian University já obteve parecer favorável ao reconhecimento em Universidades brasileiras e no portal Carolina Bori;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Os Diplomas da Obsoulute Christian University são aceitos em vários municípios brasileiros;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Revista Científica própria para publicação de artigos e trabalhos acadêmicos;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Intercâmbio Cultural para alunos obter uma experiência internacional de baixo custo, indo até a sede da Universidade nos EUA para participar da Banca de Avaliação.</S.Description>
            </S.DescriptionWrapper>
        </S.Box1>
        <S.Box2>
            <Image5 />
        </S.Box2>
        {/* Final parte 5 */}

        {/* Parte 6 */}
        <S.Box2>
            <Image6 />
        </S.Box2>
        <S.Box1>
            <S.TitleOneWrapper>
            <S.TitleOne>DOCUMENTOS ACADÊMICOS</S.TitleOne>
            </S.TitleOneWrapper>
            <S.TitleOtwe>Documentos Acadêmicos entregue aos alunos, após sua formação.</S.TitleOtwe>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Diploma Apostilado;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Histórico Escolar do Curso;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Ata na Banca;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Ementário das Disciplinas;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Carta Constância;</S.Description>
            </S.DescriptionWrapper>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />Declaração de Vínculo Acadêmico;</S.Description>
            </S.DescriptionWrapper>
        </S.Box1>
        {/* Final Parte 6 */}
        

    </S.Container>
)

export default SectionsInfo