import styled from 'styled-components'
import media from 'styled-media-query'
import Img from "gatsby-image"

export const Image = styled(Img)`
    width: 450px;

    ${media.lessThan("small")`
    width: 250px;
`}
`