import styled from 'styled-components'
import { MessageAltAdd} from '@styled-icons/boxicons-solid/MessageAltAdd'
import media from 'styled-media-query'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 32px 32px 0 32px;

    ${media.lessThan("large")`

  `}

    ${media.lessThan("medium")`

  `}

  ${media.lessThan("small")`
  margin: 10px 32px 0 32px;
  `}
`

export const TitleWrapper = styled.div`
  border-left: solid 5px var(--orange-color);
  width: 100%;
  margin-bottom: 11px;
`

export const Title = styled.h1`
    font-size: 42px;
    font-weight: 700;
    color: var(--blue-color);
    margin: 10px;

    ${media.lessThan("medium")`
    font-size: 32px;
  `}
`

export const Acordeon = styled.div`
    width: 100%;
`

export const AcordeonLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    font-size: 22px;
    font-weight: 700;
    text-decoration: none;
    color: rgba(255,255,255,.8);
    background-color: var(--blue-color);
    width: 100%;

    ${media.lessThan("medium")`
      font-size: 18px;
`}
`
export const IconAdd = styled(MessageAltAdd)`
    min-width: 32px;
    height:32px;
    color: rgba(255,255,255,.8);
    
`

export const Description = styled.p`
    font-size: 16px;
    color: rgba(255,255,255,.8);
    padding: 2rem;
    line-height: 1.5;
`

export const LinkWapper = styled.div`
  a {
    color: #fff;
  }
`