import React from 'react'

import * as S from './styled'
import Subscribe from '../../components/Subscribe'

const Form = () => (
    <S.Container>
        <S.Title>Fale com um Consultor!</S.Title>
        <S.description>
        Não demoramos a lhe atender.<br/>
        Deixe seu contato!
        </S.description>
        <Subscribe />
    </S.Container>
)

export default Form