import React from 'react'

import LogoPoint from '../../components/LogoPoint'

import * as S from './styled'

const Footer = () => (

        <S.Container>
        <S.Title>Parceria Educacional</S.Title>
        <S.ItemsWrapper>
            <LogoPoint />
            <S.Description>
            Copyright © 2022 Metodologia EaD | Todos os direitos reservados. (82) 98719-5545
            </S.Description>
        </S.ItemsWrapper>
    </S.Container>
)

export default Footer