import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SubscribeSucess from '../../components/SubscribeSucess'

import * as S from './styled'

const Price = () => {

    const { allMarkdownRemark } = useStaticQuery(graphql`
    query InfoPrices {
        allMarkdownRemark {
          edges {
            node {
              frontmatter {
                valor_mestrado
                valor_doutorado
              }
            }
          }
        }
      }
    `)

    const infoPrices = allMarkdownRemark.edges

    return (
        <>
        { infoPrices.map(
            ({
            node: {
                frontmatter: { valor_mestrado, valor_doutorado },
            },
        }) => (
            <S.Container>
            <S.Title>Plano de Pagamento</S.Title>
            <S.PriceWrapper>
                <S.TitlePriceWrapper>
                    <S.IconWrapper>
                    <S.Icon />
                    <S.TitlePrice>Material Didático Online</S.TitlePrice>
                    </S.IconWrapper>
                    <S.TitleItem>Valor contratual do curso a partir de:</S.TitleItem>
                    <S.TitleItem>Mestrado - Mensalidade <S.PriceAfter>{valor_mestrado}</S.PriceAfter></S.TitleItem>
                    <S.TitleItem>Doutorado - Mensalidade <S.PriceAfter>{valor_doutorado}</S.PriceAfter></S.TitleItem>
                    <S.TitleItem>Obs: Valor fixado em reais na data da matrícula, vingente até o final do curso.</S.TitleItem>
                </S.TitlePriceWrapper>
            </S.PriceWrapper>
            <S.ButtomWrapper>
                <SubscribeSucess />
            </S.ButtomWrapper>
            </S.Container>
        )
    )}           
     </>
)
}

export default Price