import styled from 'styled-components'
import media from 'styled-media-query'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 50px; 
    background-color: #0d2945;
    width: 100%;

    ${media.lessThan("medium")`

  `}
`

export const Title = styled.h1`
    font-size: 18px;
    font-weight: 700;
    color: rgba(255,255,255,.8);
    margin: 10px;
`

export const ItemsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    ${media.lessThan("large")`
    flex-direction: column; 
    margin-bottom: 32px;
    text-align: center;
  `}

    ${media.lessThan("medium")`
    flex-direction: column; 
    margin-bottom: 10px;
    text-align: center;
  `}

`
export const Description = styled.div`
    position: relative;
    top: 30px;
    color: rgba(255,255,255,.8);
    margin: 10px ;

    ${media.lessThan("medium")`
    top: 0;
  `}
`