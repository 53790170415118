import React from 'react'

import Form  from '../../components/Form'

import * as S from './styled'

const Matricula = () => (
    <S.Container>

        {/* Parte 1 */}
        <S.Box1>
            <S.TitleOneWrapper>
            <S.TitleOne>MATRÍCULA</S.TitleOne>
            </S.TitleOneWrapper>
            <S.TitleOtwe>Inscrições Abertas</S.TitleOtwe>
            <S.DescriptionWrapper>
                <S.Description><S.Icon1 />  Nós acreditamos nas pessoas
e oferecemos programas acadêmicos feitos para transformar vidas.</S.Description>
            </S.DescriptionWrapper>
        </S.Box1>
        <S.Box2>
            <Form />
        </S.Box2>
        {/* Final parte 1 */}
        

    </S.Container>
)

export default Matricula