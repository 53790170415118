import React from "react"

import Layout from "../components/Layout"
import CardHome from '../components/CardHome'



import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
      <CardHome/>
  </Layout>
)

export default IndexPage
